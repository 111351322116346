<template>
	<Layout :footer-style="2" :header-transparency="true">
		<div class="slider-area bg-transparent slider-style-1"></div>

			<div id="safety-construction-planning" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/service1.png" alt="安全建设规划">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										安全建设规划
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										北京安炘结合多年的安全咨询服务经验，提出了基础设施—安全体系—态势感知的安全金字塔建设理念，帮助客户逐步实现对安全风险的闭环管理和智能管控。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/service/safety-construction-planning')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="level-protection" class="rwt-about-area rn-section-gap bg-light">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										等级保护咨询
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										北京安炘结合多年的安全咨询服务经验，提出了基础设施—安全体系—态势感知的安全金字塔建设理念，帮助客户逐步实现对安全风险的闭环管理和智能管控。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/level-protection')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/service2.png" alt="等级保护咨询">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="security-risk-assessment" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/service3.png" alt="安全风险评估">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										安全风险评估
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										北京安炘具备权威的ISCCC风险评估资质，遵循国家风险评估规范和业界最佳实践为用户提供全面的风险评估咨询服务和风险管理解决方案。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/service/security-risk-assessment')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="vulnerability-scanning" class="rwt-about-area rn-section-gap bg-light">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										漏洞扫描服务
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										北京安炘漏洞扫描服务主要是通过评估工具以本地扫描的方式对评估范围内的系统和网络进行安全扫描，查找网络结构、网络设备、服务器主机、数据和用户账号/口令等安全对象目标存在的安全风险、漏洞和威胁。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/service/vulnerability-scanning')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/service4.png" alt="漏洞扫描服务">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="penetration-testing" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/service5.png" alt="渗透测试服务">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										渗透测试服务
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										渗透测试过程主要依据安全专家已经掌握的安全漏洞信息，模拟黑客的真实攻击方法对系统和网络进行非破坏性质的攻击性测试。北京安炘渗透测试服务主要针对系统主机进行，因此将占用主机系统及其所在的网络环境的部分资源。同时需要工作人员的一些配合（某些特定条件下，如为了节省时间破解密码，渗透测试将首先得到普通用户权限），对于其他的资源没有特殊的要求。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/service/penetration-testing')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="security-hardening" class="rwt-about-area rn-section-gap bg-light">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										安全加固服务
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										安全加固是针对进行评估后的主机的漏洞和脆弱性采取的一种有效的安全手段，可以帮助系统抵御外来的入侵和蠕虫病毒的袭击，使系统可以长期保持在高度可信的状态。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/service/security-hardening')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/service6.png" alt="安全加固服务">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="incident-response" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/service7.png" alt="应急响应服务">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										应急响应服务
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										网络信息化建设不断深入，加强各类设备、系统以及信息与网络安全等方面应对应急事件的处理能力将是运维项目面临的一项重要任务。大部分企事业单位自身尚没有足够的资源和能力对安全事故作出反应。北京安炘在第一时间内对客户信息系统面临的紧急安全事件进行应急响应。紧急安全事故包括：大规模病毒爆发、网络入侵事件、拒绝服务攻击、主机或网络异常事件等。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/service/incident-response')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="security-training" class="rwt-about-area rn-section-gap bg-light">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										安全培训服务
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										信息安全意识培训面向非技术类用户。目的是通过大量的当前典型安全事件导入，从感性认知层面对目前的信息安全威胁给予直观、形象的描述，使用户能对当前的信息安全威胁有一个深刻的认识。同时通过案例介绍的方式对用户日常工作、生活中经常用到的一些客户端应用工具、系统的安全威胁进行分析，并阐明具体的防范措施，最终协助建立起适合个人、企业的用户行为基准。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/service/security-training')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/service8.png" alt="安全培训服务">
							</div>
						</div>
					</div>
				</div>
			</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    import Icon from '../components/icon/Icon.vue'
    import Separator from '../components/elements/separator/Separator.vue'
		import Button from "@/components/elements/button/Button.vue";

    export default {
        name: 'Home',
        components: {Button, Icon, Layout, Separator},
        data() {
            return { }
        }
    }
</script>
<style lang="scss" scoped>
.slider-area {
	background-image: url("../assets/images/_new/banner3.png");
}
.blue-title {
	font-size: 34px;
	color: #102e98;
	line-height: 34px;
	font-weight: 400;
}
.desc{
	font-size: 20px;
	line-height: 36px;
	color: #666;
	margin: 35px 0 25px;
}
</style>
