import AppFunctions from '../helpers/AppFunctions'

export default {
    data() {
        return {
            posts: [
                {
                    id: 'about',
                    img: require(`@/assets/images/_new/banner2.png`),
                    titles: ['等级保护', '等保概念'],
                    links: ['/level-protection'],
                    blocks: [
                        {
                            content: [
                                {
                                    p: '对信息系统分等级进行安全保护和监管；对信息安全产品的使用实行分等级管理；对信息安全事件实行分等级响应、处置。将全国的信息系统（包括网络）按照重要性和受破坏后的危害性分成五个安全保护等级（从第一级到第五级逐级增高）,定级后第二级以上系统到公安机关备案,公安机关审核合格后颁发备案证明;各单位各部门根据系统等级按照国家标准进行安全建设整改;聘请测评机构进行等级测评;公安机关定期开展监督、检查、指导。',
                                }
                            ],
                        },
                        {
                            title: '等级保护流程',
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/p1.png`),
                                }
                            ],
                        },
                        {
                            title: '定级划分',
                            content: [
                                {
                                    p: '《信息安全等级保护管理办法》规定，国家信息安全等级保护坚持自主定级、自主保护的原则。信息系统的安全保护等级应当根据信息系统在国家安全、经济建设、社会生活中的重要程度，信息系统遭到破坏后对国家安全、社会秩序、公共利益以及公民、法人和其他组织的合法权益的危害程度等因素确定。信息系统的安全保护等级分为以下五级，一至五级等级逐级增高：'
                                },
                                {
                                    p: '第一级，信息系统受到破坏后，会对公民、法人和其他组织的合法权益造成损害，但不损害国家安全、社会秩序和公共利益。第一级信息系统运营、使用单位应当依据国家有关管理规范和技术标准进行保护。'
                                },
                                {
                                    p: '第二级，信息系统受到破坏后，会对公民、法人和其他组织的合法权益产生严重损害，或者对社会秩序和公共利益造成损害，但不损害国家安全。国家信息安全监管部门对该级信息系统安全等级保护工作进行指导。'
                                },
                                {
                                    p: '第三级，信息系统受到破坏后，会对社会秩序和公共利益造成严重损害，或者对国家安全造成损害。国家信息安全监管部门对该级信息系统安全等级保护工作进行监督、检查。'
                                },
                                {
                                    p: '第四级，信息系统受到破坏后，会对社会秩序和公共利益造成特别严重损害，或者对国家安全造成严重损害。国家信息安全监管部门对该级信息系统安全等级保护工作进行强制监督、检查。'
                                },
                                {
                                    p: '第五级，信息系统受到破坏后，会对国家安全造成特别严重损害。国家信息安全监管部门对该级信息系统安全等级保护工作进行专门监督、检查。'
                                }
                            ]
                        },
                        {
                            title: '实施意义',
                            content: [
                                {
                                    p: '<strong>1）法律法规要求</strong>' +
                                        '<div>《网络安全法》明确规定信息系统运营、使用单位应当按照网络安全等级保护制度要求，履行安全保护义务，如果拒不履行，将会受到相应处罚。</div>' +
                                        '<div>第二十一条：国家实行网络安全等级保护制度。网络运营者应当按照网络安全等级保护制度的要求，履行下列安全保护义务，保障网络免受干扰、破坏或者未经授权的访问，防止网络数据泄露或者被窃取、篡改。</div>',
                                },
                                {
                                    p: '<strong>2）行业要求</strong>' +
                                        '<div>在金融、电力、广电、医疗、教育等行业，主管单位明确要求从业机构的信息系统（APP）要开展等级保护工作。</div>'
                                },
                                {
                                    p: '<strong>3）企业系统安全的需求</strong>' +
                                        '<div>信息系统运营、使用单位通过开展等级保护工作可以发现系统内部的安全隐患与不足之处，可通过安全整改提升系统的安全防护能力，降低被攻击的风险。</div>'
                                }
                            ]
                        },
                        {
                            title: '等级保护咨询服务目标',
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/p2.png`),
                                    width: '850px'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'level',
                    img: require(`@/assets/images/_new/banner2.png`),
                    titles: ['等级保护', '定级'],
                    links: ['/level-protection'],
                    blocks: [
                        {
                            title: '',
                            content: [
                                {
                                    p: '根据《信息安全等级保护管理办法》规定，国家信息安全等级保护坚持自主定级、自主保护的原则。信息系统的安全保护等级应当根据信息系统在国家安全、经济建设、社会生活中的重要程度，信息系统遭到破坏后对国家安全、社会秩序、公共利益以及公民、法人和其他组织的合法权益的危害程度等因素确定。信息系统安全包括：业务信息安全和系统服务安全，与之相关的受侵害客体和对客体的侵害程度可能不同，因此信息系统定级也应由业务信息安全和系统服务安全两个方面确定。信息系统的安全保护等级分为五级，一至五级等级逐级增高。'
                                }
                            ],
                        },
                        {
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/p3.jpg`),
                                }
                            ],
                        },
                    ]
                },
                {
                    id: 'filing',
                    img: require(`@/assets/images/_new/banner2.png`),
                    titles: ['等级保护', '备案'],
                    links: ['/level-protection'],
                    blocks: [
                        {
                            title: '',
                            content: [
                                {
                                    p: '运营使用单位按照管理规范和技术标准，选择管理办法要求的信息安全产品，建设符合等级要求的信息安全设施，建立安全组织，制定并落实安全管理制度。准备相关备案材料，到当地公安机关进行备案，当地公安机关审核受理备案材料合格后，申领公安机关颁发的《备案证明》。'
                                }
                            ],
                        },
                        {
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/p4.png`),
                                }
                            ],
                        },
                    ]
                },
                {
                    id: 'developing',
                    img: require(`@/assets/images/_new/banner2.png`),
                    titles: ['等级保护', '建设整改'],
                    links: ['/level-protection'],
                    blocks: [
                        {
                            content: [
                                {
                                    p: '依据《等级保护安全设计要求》、《等级保护基本要求》对被测系统进行建设整改，对于未达到安全等级保护要求的，运营、使用单位应当进行整改。北京安炘提供整改咨询服务，在整改过程中发现单位系统内、外部存在的安全风险和脆弱性，通过整改之后，提高信息系统的信息安全防护能力，降低系统被各种攻击的风险。整改完成应当将整改报告报公安机关备案。受理备案的公安机会对三级、四级信息系统进行检查，检查频次同测评频次。五级信息系统接受国家制定的专门部门检查。'
                                }
                            ],
                        },
                        {
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/p5.png`),
                                }
                            ],
                        },
                    ]
                },
                {
                    id: 'testing',
                    img: require(`@/assets/images/_new/banner2.png`),
                    titles: ['等级保护', '等保测评'],
                    links: ['/level-protection'],
                    blocks: [
                        {
                            content: [
                                {
                                    p: '等保测评是测评机构依据国家信息安全保护制度规定进行测评。北京安炘协助客户按照有关管理规范和技术标准，运用科学的手段和方法，对处理特定应用的信息系统及安全技术进行测评和安全管理咨询服务。判定受测系统的技术和管理级别与所定安全等级要求的符合程度，基于符合程度给出是否满足所定安全等级的结论，针对安全不符合项提出安全整改建议，并协助客户接受测评机构在测评过程中进行指导运营。配合测评中心开展等级测评工作，并保障顺利通过等保测评获得测评报告。'
                                }
                            ],
                        },
                        {
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/p6.jpg`),
                                }
                            ],
                        },
                    ]
                },
                {
                    id: 'product',
                    img: require(`@/assets/images/_new/banner2.png`),
                    titles: ['等级保护', '整改产品'],
                    links: ['/level-protection'],
                    blocks: [
                        {
                            title: '典型应用-等保一体机',
                            content: [
                                {
                                    p: '通过部署 USP-H 能够满足等级保护制度在安全边界、安全通信网络、计算环境安全和安全管理中心的部分测评要求；通过部署USP-D和USP-M能够比较全面地满足等级保护各方面的测评要求。'
                                },
                                {
                                    p: '随着网络安全上升到国家战略高度，等级保护制度成为我国网络安全领域的一项基本制度。2017年6月1日正式开始实施的《中华人民共和国网络安全法》明确规定国家实行网络安全等级保护制度，《国家关键信息基础设施安全保护条例》也规定国家关键信息基础设施应该在等级保护制度基础上实行重点保护。'
                                },
                                {
                                    p: '网络安全等级保护制度将全国的信息系统（包括网络）按照重要性和受破坏后的危害性分成五个安全保护等级（从第一级到第五级逐级增高）。根据GB/T22239-2019《网络安全等级保护基本要求》，三级安全保护能力应具有能够对抗来自大型的、有组织的团体（如一个商业情报组织或犯罪组织等），拥有较为丰富资源（包括人员能力、计算能力等）的威胁源发起的恶意攻击、较为严重的自然灾难（灾难发生的强度较大、持续时间较长、覆盖范围较广（地区性）等）以及其他相当危害程度（内部人员的恶意威胁、设备的较严重故障等）威胁的能力，并在威胁发生后，能够较快恢复绝大部分功能。在网络安全等级保护定级、备案、建设整改、测评和监督检查等五个环节中，建设整改环节是重心。根据标准文件，信息系统安全防护需要满足如下要求：'
                                },
                                {
                                    img: require(`@/assets/images/_new/detail/p7.png`),
                                }
                            ],
                        }
                    ]
                },
                {
                    id: 'safety-construction-planning',
                    img: require(`@/assets/images/_new/banner3.png`),
                    titles: ['安全咨询与服务', '安全建设规划'],
                    links: ['/service'],
                    blocks: [
                        {
                            content: [
                                {
                                    p: '北京安炘安全咨询专家团队凭借多年的行业经验和先进的方法，从分析安全现状和安全需求出发，遵照合规和监管要求，借鉴成熟的信息安全管理模型和最佳实践，为企业量身打造适用当前业务的安全保障体系，帮助企业构建信息安全建设的远景、目标、框架、任务和行动路线。安全规划咨询帮助客户确立安全目标，并通过风险评估等方法进行安全现状分析，输出安全差距分析报告，藉此进行安全建设方案设计、实施及后续技术服务，完成咨询-建设-服务的安全能力交付链条。'
                                }
                            ],
                        },
                        {
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/p8.png`),
                                    width: '800px'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'security-risk-assessment',
                    img: require(`@/assets/images/_new/banner3.png`),
                    titles: ['安全咨询与服务', '安全风险评估'],
                    links: ['/service'],
                    blocks: [
                        {
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/p9.jpg`),
                                    width: '480px'
                                },
                                {
                                    p: '信息安全风险评估是一个识别、控制、降低或消除可能影响信息系统的安全风险的过程。通过梳理客户的IT资产，识别和评估信息资产的重要性、安全威胁的可能性、安全脆弱性的严重程度、以及安全控制措施的有效性等要素，对重要信息系统所面临的信息安全风险进行识别和定性评估，并对所有评估发现的不可接受风险给出对应的安全处置和加固建议，协助客户提升对重要信息系统的安全风险管理和安全保障能力。'
                                },
                                {
                                    p: '北京安炘风险评估服务对客户现有网络中的网络架构、网络协议、系统、数据库等资产安全现状进行了解，确定在系统的具体环境下到底存在什么安全漏洞和安全隐患，一旦这些脆弱性被黑客利用会造成什么样的资产风险和影响。在此基础上制定适合于自身的安全目标和安全级别，然后根据所要达到的安全目标和安全级别，在充分考虑安全性的基础之上选择和实施相应的安全建设方案。建议在部署基本的安全系统并运营一段时间后，定期通过完善的风险评估服务检查整个系统面临的危险并根据评估结果对网络加以调整和优化。'
                                }
                            ],
                        },
                        {
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/p10.png`),
                                    width: '680px'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'vulnerability-scanning',
                    img: require(`@/assets/images/_new/banner3.png`),
                    titles: ['安全咨询与服务', '漏洞扫描服务'],
                    links: ['/service'],
                    blocks: [
                        {
                            content: [
                                {
                                    p: '漏洞扫描技术是一类重要的网络安全技术。它和防火墙、入侵检测系统互相配合，能够有效提高网络的安全性。通过对网络的扫描，网络管理员能了解网络的安全设置和运行的应用服务，及时发现安全漏洞，客观评估网络风险等级。漏洞是在硬件、软件、协议的具体实现或系统安全 策略上存在的缺陷，从而可以使攻击者能够在未授权的情况下访问或破坏系统。是受限制的计算机、组件、应 用程序或其他联机资源的无意中留下的不受保护的入口 点。漏洞扫描是指基于漏洞数据库，通过扫描等手段对 指定的远程或者本地计算机系统的安全脆弱性进行检测， 发现可利用漏洞的一种安全检测行为。'
                                },
                                {
                                    p: '北京安炘漏洞扫描服务主要是通过评估工具以本地扫描的方式对评估范围内的系统和网络进行安全扫描，查找网络结构、网络设备、服务器主机、数据和用户账号/口令等安全对象目标存在的安全风险、漏洞和威胁。'
                                },
                                {
                                    p: '通过定期安全扫描，可以验证各类操作系统的安全补丁情况，方便及时地提示对操作系统的安全补丁进行更新，发现已有的补丁自动升级系统没有进行升级的系统补丁，全面加强系统的补丁管理。'
                                },
                                {
                                    p: '为了确保扫描的可靠性和安全性，北京安炘与客户一起确定扫描计划。计划主要包括扫描开始时间、扫描对象、预计结束时间、扫描项目、预期影响、需要对方提供的支持等等。'
                                },
                                {
                                    p: '安全扫描是利用安全评估工具对绝大多数评估范围内的主机、网络设备等系统环境进行的漏洞扫描。但是，评估范围内的网络设备安全策略的弱点和部分主机的安全配置错误等并不能被扫描器全面发现，因此有必要对评估工具扫描范围之外的系统和设备进行人工安全检查。主机安全检查服务是登录到主机上进行安全检查分析，并提出安全检查报告。'
                                }
                            ],
                        }
                    ]
                },
                {
                    id: 'penetration-testing',
                    img: require(`@/assets/images/_new/banner3.png`),
                    titles: ['安全咨询与服务', '渗透测试服务'],
                    links: ['/service'],
                    blocks: [
                        {
                            content: [
                                {
                                    p: '渗透测试过程主要依据安全专家已经掌握的安全漏洞信息，模拟黑客的真实攻击方法对系统和网络进行非破坏性质的攻击性测试。这个过程包括对系统的任何弱点、技术缺陷或漏洞的主动分析，这个分析是从一个攻击者可能存在的位置来进行的，并且从这个位置有条件主动利用安全漏洞。北京安炘渗透测试服务主要针对系统主机进行，因此将占用主机系统及其所在的网络环境的部分资源。同时需要工作人员的一些配合（某些特定条件下，如为了节省时间破解密码，渗透测试将首先得到普通用户权限），对于其他的资源没有特殊的要求。'
                                },
                                {
                                    p: '黑客的攻击入侵需要利用目标网络的安全弱点，渗透测试也是同样的道理。它模拟真正的黑客入侵攻击方法，以人工渗透为主，辅助以攻击工具的使用，这样保证了整个渗透测试过程都在可以控制和调整的范围之内。'
                                },
                                {
                                    p: '由于采用可控制的、非破坏性质的渗透测试，因此不会对渗透测试对象造成严重的影响。在渗透测试结束后，系统将保持正常运行状态。以下是渗透测试的流程：'
                                },
                                {
                                    img: require(`@/assets/images/_new/detail/p11.png`),
                                    width: '480px'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'security-hardening ',
                    img: require(`@/assets/images/_new/banner3.png`),
                    titles: ['安全咨询与服务', '安全加固服务'],
                    links: ['/service'],
                    blocks: [
                        {
                            content: [
                                {
                                    p: '网络安全是动态的，需要时刻关注最新漏洞和安全动态，制定更新的安全策略以应付外来入侵和蠕虫病毒等威胁。针对业务服务器的漏洞和脆弱性，定期的进行安全加固，可以使系统有效的抵御外来的入侵和蠕虫病毒的袭击，使系统可以长期保持在高度可信的状态。安全加固是针对进行评估后的主机的漏洞和脆弱性采取的一种有效的安全手段，可以帮助系统抵御外来的入侵和蠕虫病毒的袭击，使系统可以长期保持在高度可信的状态。通常对系统和应用服务的安全加固包括如下方面：<strong>安装最新补丁、帐号、口令策略调整、网络与服务加固、文件系统权限增强、日志审核功能增强、安全性增强。</strong>'
                                },
                                {
                                    img: require(`@/assets/images/_new/detail/p12.png`),
                                    width: '480px'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'incident-response',
                    img: require(`@/assets/images/_new/banner3.png`),
                    titles: ['安全咨询与服务', '应急响应服务'],
                    links: ['/service'],
                    blocks: [
                        {
                            content: [
                                {
                                    p: '网络信息化建设不断深入，加强各类设备、系统以及信息与网络安全等方面应对应急事件的处理能力将是运维项目面临的一项重要任务。大部分企事业单位自身尚没有足够的资源和能力对安全事故作出反应。网络安全的发展日新月异，无法实现一劳永逸的安全，所以当紧急安全问题发生，一般技术人员又无法迅速解决的时候，及时发现问题、解决问题就必须依靠专业的应急响应服务来实现。'
                                },
                                {
                                    p: '北京安炘在第一时间内对客户信息系统面临的紧急安全事件进行应急响应。紧急安全事故包括：大规模病毒爆发、网络入侵事件、拒绝服务攻击、主机或网络异常事件等。'
                                },
                                {
                                    p: '服务内容：'
                                },
                                {
                                    p: '<ul><li>接到客户应急响应请求时迅速启动响应预案；</li><li>接到客户远程应急响应请求发出30分钟内指派工程师进行处理，无论能否解决问题每天都会返回处理情况简报，直到此次响应服务结束；</li><li>在远程应急响应2小时后还不能解决问题，则立即执行本地应急响应流程，在本地应急响应请求发出后，工程师应在2小时内到达事故现场，协助现场人员进行问题处理；</li><li>响应服务完成后，安全厂商的服务人员应整理详细的事故处理报告，内容包括事故原因分析、已造成的影响、处理办法、处理结果、预防和改进建议等提交给客户相关人员；</li><li>远程应急响应和本地应急响应提供7×24响应。</li></ul>'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'security-training',
                    img: require(`@/assets/images/_new/banner3.png`),
                    titles: ['安全咨询与服务', '安全培训服务'],
                    links: ['/service'],
                    blocks: [
                        {
                            title: '信息安全意识培训',
                            content: [
                                {
                                    p: '面向非技术类用户。目的是通过大量的当前典型安全事件导入，从感性认知层面对目前的信息安全威胁给予直观、形象的描述，使用户能对当前的信息安全威胁有一个深刻的认识。同时通过案例介绍的方式对用户日常工作、生活中经常用到的一些客户端应用工具、系统的安全威胁进行分析，并阐明具体的防范措施，最终协助建立起适合个人、企业的用户行为基准。'
                                }
                            ]
                        },
                        {
                            title: '信息安全技术培训',
                            content: [
                                {
                                    p: '面向信息安全技术类用户，例如系统管理员、安全技术员等。目的是通过培训让其在系统及应用层面上了解常见通用操作系统架构以及其安全性，掌握相关系统的安全配置和管理能力；在网络层面上了解常见的网络安全协议掌握网络安全协议以及路由交换常见安全配置；同时通过实验了解常见的网络攻击技术原理，掌握常见的攻击防护方法。'
                                }
                            ]
                        },
                        {
                            title: '信息安全产品培训',
                            content: [
                                {
                                    p: '通过详细介绍系列安全产品的工作原理、安装部署和故障排错方法，同时结合一些产品实验加强对产品的了解，使相关人员能灵活利用这些安全产品解决组织的实际安全问题。'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'cloud-safety',
                    img: require(`@/assets/images/_new/banner5.png`),
                    titles: ['产品解决方案', '云安全解决方案'],
                    links: ['/solution'],
                    blocks: [
                        {
                            title: "DDOS高防IP",
                            content: [
                                {
                                    p: 'DDOS高防IP是针对互联网服务器（包括公有云、IDC主机）在遭 受大流量DDOS攻击后导致服务不可用的情况下，推出的付费服 务，用户可通过配置高防IP，将攻击流量引流到高防IP，确保源站的稳定可靠性。'
                                }
                            ]
                        },
                        {
                            title: "WEB应用防火墙",
                            content: [
                                {
                                    p: 'Web应用防火墙是针对网站或者APP的业务流量进行恶意特征识别及防护，将正常、安全的流量回溯到服务器。避免网站服务器被恶意入侵，保障业务的核心数据安全，解决恶意攻击导致的服务器异常问题。'
                                }
                            ]
                        },
                        {
                            title: "DDOS防火墙",
                            content: [
                                {
                                    p: 'DDOS防火墙是一套DDOS流量清晰设备，也是高防IP产品的核心 基础，拥有单台80Gbps/U、集群1.92Tbps/机柜的清洗密度，相对于同类产品具有支持分布式部署架构、虚拟化Layer4/Lay-er7/WAF软件架构、定制化CC防御等特性，可运用于IDC、大型企业等各种私有化部署。'
                                }
                            ]
                        },
                        {
                            title: "应用场景",
                            content: [
                                {
                                    p: '<ul><li>网络游戏一直是DDoS/CC攻击的重灾区，并且也是对防护效果、网络质量要求最高的行业，共启高防通过大带宽BGP防护网络，多机房多线路同步防御，肉鸡行为以及无SDK加密水印三重防御CC的技术，为客户提供高质量的防御方案。</li><li>智慧城市项目在落地过程中经常会遇到竞争对手的恶意攻击，而车牌识别、缴费等部署在公网对外提供服务的接口，是黑客攻击的重点，共启高防通过业务行为分析、肉鸡地域检测等方式为智慧城市项目提供完善的防护方案。</li><li>电商平台</li><li>电商APP、小程序、公众号等业务的支付、搜索等接口经常会面临应用层CC攻击，共启高防定制化的肉鸡行为检测，攻击报文特征深度检验技术为电商平台提供了强有力的防护方案。</li><li>互联网金融由于其行业特殊性，其对高防平台的稳定性要求极高，共启高防多机房联动共同防御方式，保证单一机房故障时业务不受影响，为互金提供坚若磐石的防御方案。</li></ul>'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'mail-safety',
                    img: require(`@/assets/images/_new/banner5.png`),
                    titles: ['产品解决方案', '邮件安全解决方案'],
                    links: ['/solution'],
                    blocks: [
                        {
                            title: 'SSQR 邮件安全网关',
                            content: [
                                {
                                    p: '新一代邮件过滤系统，高效过滤普通垃圾邮件与高危钓鱼，病毒邮件。在全面安全保护的同时提供差异行为管理功能。即可满足用户自主管理放行邮件，亦可管控恶意邮件流窜。同时垃圾与威胁邮件双核过滤技术，拥有完善的特征库、指纹库和恶意网址数据库云端差分更新技术，搭载流量控制防御引擎、社交工程侦测引擎，附件内容分析引擎，有效缩短 0day 攻击的风险，提升系统整体拦截效能。网关架构于企业邮件服务器前，有效降低各式邮件攻击风险，如 BEC，垃圾/广告邮件、钓鱼邮件(社交工程)、伪造攻击邮件、病毒邮件、携带病毒附件邮件等。'
                                }
                            ]
                        },
                        {
                            title: 'MSE 邮件审计管理',
                            content: [
                                {
                                    p: '邮件是企业间数据传递的主要渠道，也是大量敏感信息储存的媒介，如何有效管理邮件（邮件审计）已成为传输中的数据泄露防护（Data Loss Prevention，简称 DLP）的重要课题。企业在管理邮件时，首要任务为过滤病毒类邮件、审核敏感信息邮件，例如：信用卡号、电话、地址等，并预防员工因无心操作而造成的敏感信息泄露。最棘手的问题则是如何将不断成长累积的邮件数据，以符合法规的方式长期保存，并能在需要调查或举证时，快速找到关键邮件。'
                                },
                                {
                                    p: 'Mail SQR Expert 邮件审计管理专家，提供企业通道式 DLP 完整的解决方案，以模块化的扩充功能设计，解决 DLP 加密的难题，并可深度整合第三方文件加解密或文件分级系统，提升成企业级内容感知型 DLP。功能设计遵循 3A 的管理循环，从分析 (Analysis) 收集判断，找到需管理的条件付诸行动 (Action)，最后借由审计 (Audit) 检查实际效果，找出需要再分析的情况，重新 3A 循环。'
                                }
                            ]
                        },
                        {
                            title: 'MAE 电子邮件归档管理',
                            content: [
                                {
                                    p: 'MAE 电子邮件归档管理企业在面对电子邮件管理时，最棘手的问题不外乎是该如何管理经年累月不断成长的电子邮件数据，能在符合法规的条件下长期保存，也能在需要举证时，快速搜寻找到关键的电子邮件。'
                                },
                                {
                                    p: 'Mail Archiving Expert 电子邮件归档管理除了能够解决即时邮件归档，防止使用者误删邮件外，也提供完整的邮件生命周期管理，可依年份将邮件自动搬移至不同的储存媒介，也可依部门别保留不同年限的邮件。提供弹性的调阅权限管理，搭配查询结果指定给其它管理人员做再次查询的调阅流程，精确定位出需调查的信件，避免授权过度而看到不应被调阅的信件内容。'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'device-safety',
                    img: require(`@/assets/images/_new/banner5.png`),
                    titles: ['产品解决方案', '终端安全解决方案'],
                    links: ['/solution'],
                    blocks: [
                        {
                            title: '方案优势',
                            content: [
                                {
                                    p: '用户统一身份管理提供多因素身份认证和动态身份认证能力<br>' +
                                        '终端安全环境治理提供对终端环境的多层次安全评估能力<br>' +
                                        '应用系统未知漏洞防护通过应用深层解析能力防护已知或未知漏洞<br>' +
                                        '业务数据风险安全防护依托业务代理网关可提供业务敏感数据识别能力<br>' +
                                        '快速应急响应能力基于身份的溯源分析能力，精准还原攻击行为'
                                }
                            ]
                        },
                        {
                            title: '方案详情',
                            content: [
                                {
                                    img: require(`@/assets/images/_new/detail/d1.png`),
                                    width: '480px'
                                },
                                {
                                    img: require(`@/assets/images/_new/detail/d2.png`),
                                    width: '480px'
                                }
                            ]
                        }
                    ]
                }
            ],
            AppFunctions
        }
    },
    methods: {
    },
    created() {
    }
}
