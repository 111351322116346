<template>
    <Layout :footer-style="2" :header-transparency="true">
			<div class="slider-area bg-transparent slider-style-1"></div>

			<div id="about" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/protection1.png" alt="等保概念">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										等保概念
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										对信息系统分等级进行安全保护和监管；对信息安全产品的使用实行分等级管理；对信息安全事件实行分等级响应、处置。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/level-protection/about')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="level" class="rwt-about-area rn-section-gap bg-light">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										定级
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										协助用户确认定级对象，为用户提供定级咨询服务，辅导建设单位准备定级报告，并组织专家评审，确定安全保护等级，填写定级备案表，编写定级报告。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/level-protection/level')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/protection2.png" alt="定级">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="filing" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/protection3.png" alt="备案">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										备案
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										运营使用单位按照管理规范和技术标准，选择管理办法要求的信息安全产品，建设符合等级要求的信息安全设施，建立安全组织，制定并落实安全管理制度。准备相关备案材料，到当地公安机关进行备案，当地公安机关审核受理备案材料合格后，申领公安机关颁发的《备案证明》。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/level-protection/filing')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="developing" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										建设整改
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										依据《等级保护安全设计要求》、《等级保护基本要求》对被测系统进行建设整改，对于未达到安全等级保护要求的，运营、使用单位应当进行整改。北京安炘提供整改咨询服务，在整改过程中发现单位系统内、外部存在的安全风险和脆弱性，通过整改之后，提高信息系统的信息安全防护能力，降低系统被各种攻击的风险。整改完成应当将整改报告报公安机关备案。受理备案的公安机会对三级、四级信息系统进行检查，检查频次同测评频次。五级信息系统接受国家制定的专门部门检查。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/level-protection/developing')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/protection6.jpg" alt="建设整改">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="testing" class="rwt-about-area rn-section-gap bg-light">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/protection4.png" alt="等保测评">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										等保测评
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										等保测评是测评机构依据国家信息安全保护制度规定进行测评。北京安炘协助客户按照有关管理规范和技术标准，运用科学的手段和方法，对处理特定应用的信息系统及安全技术进行测评和安全管理咨询服务。判定受测系统的技术和管理级别与所定安全等级要求的符合程度，基于符合程度给出是否满足所定安全等级的结论，针对安全不符合项提出安全整改建议，并协助客户接受测评机构在测评过程中进行指导运营。配合测评中心开展等级测评工作，并保障顺利通过等保测评获得测评报告。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/level-protection/testing')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="product" class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										整改产品
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										调查显示，80%的机构或企业都曾经因为网络安全问题而遭受损失，尽管他们已经投入了可观的安全预算和资源。建立专业安全团队，采购各种安全产品，并持续更新和维护管理对绝大多数用户尤其是中小机构都是一件非常困难的事情。 本质上，我们需要的是安全能力而不是形式化的安全产品，所以以交付最终安全能力为目标的安全赋能服务将是中小机构(SMO)的首选。
									</p>
									<br>
									<Button
										size="small"
										variant-button-class="text-underline"
										data-aos="fade-up"
										:is-link="false"
										@btnClick="$router.push('/level-protection/product')"
									>
										<span>查看详情</span>
									</Button>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/protection5.png" alt="整改产品">
							</div>
						</div>
					</div>
				</div>
			</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    import Icon from '../components/icon/Icon.vue'
    import Separator from '../components/elements/separator/Separator.vue'
		import Button from "@/components/elements/button/Button.vue";

    export default {
        name: 'Home',
        components: {Button, Icon, Layout, Separator},
        data() {
            return { }
        }
    }
</script>
<style lang="scss" scoped>
.slider-area {
	background-image: url("../assets/images/_new/banner2.png");
}
.blue-title {
	font-size: 34px;
	color: #102e98;
	line-height: 34px;
	font-weight: 400;
}
.desc{
	font-size: 20px;
	line-height: 36px;
	color: #666;
	margin: 35px 0 25px;
}
</style>
