<template>
	<Layout :footer-style="2" :header-transparency="true">
		<div class="slider-area bg-transparent slider-style-1"></div>

		<div class="rwt-about-area rn-section-gap">
			<div class="container">
				<div class="row row--30 align-items-center">
					<div class="col-lg-5 col-sm-12">
						<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
							<img class="w-100" src="../assets/images/_new/about1.png" alt="等保概念">
						</div>
					</div>
					<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
						<div class="content">
							<div class="section-title">
								<p class="desc" data-aos="fade-up" data-aos-delay="310">
									公司致力于为客户提供新一代智能安全解决方案和咨询服务，保障业务安全，助力企业发展，自主研发的TDR智能安全运营服务平台，威胁检测与响应解决方案，及安全咨询服务已经获得包括金融、大中型企业、教育等行业的近千家客户的高度认可。
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="rwt-about-area rn-section-gap bg-light">
			<div class="container">
				<div class="row row--30 align-items-center">
					<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
						<div class="content">
							<div class="section-title">
								<p class="desc" data-aos="fade-up" data-aos-delay="310">
									安炘嘉得北京设有研发和测试基地。依托于在全国建立的代理商体系以及服务网络为用户提供精准、专业的服务。
									<br>
									公司成立以来，安炘人秉承“追求卓越，厚德载物”的价值理念，力争打造国内新一代智能安全解决方案的卓越品牌。
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-5 col-sm-12">
						<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
							<img class="w-100" src="../assets/images/_new/about2.png" alt="定级">
						</div>
					</div>
				</div>
			</div>
		</div>

	</Layout>
</template>

<script>
import Layout from '../components/common/Layout.vue'
import Icon from '../components/icon/Icon.vue'
import Separator from '../components/elements/separator/Separator.vue'
import Button from "@/components/elements/button/Button.vue";

export default {
	name: 'Home',
	components: {Button, Icon, Layout, Separator},
	data() {
		return { }
	}
}
</script>
<style lang="scss" scoped>
.slider-area {
	background-image: url("../assets/images/_new/banner4.jpg");
}
.blue-title {
	font-size: 34px;
	color: #102e98;
	line-height: 34px;
	font-weight: 400;
}
.desc{
	font-size: 20px;
	line-height: 36px;
	color: #666;
	margin: 35px 0 25px;
}
</style>
