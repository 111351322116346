<template>
    <Layout :footer-style="2" :header-transparency="true">
			<div v-if="post.img"
					 class="slider-area bg-transparent slider-style-1"
					 :style="{backgroundImage: `url(${post.img})`}"
			>
			</div>

			<BreadcrumbNew :links="post.links" :titles="post.titles"/>

			<div class="blog-details-content pt--20 pb--20">
				<div class="container">
					<div class="row">
						<div v-for="block in post.blocks" class="col-lg-10 offset-lg-1 detail-content" data-aos="fade-up" data-aos-delay="100">
							<div v-if="block.title" class="title">
								<div class="block-1">
									<div class="block-2">
										<div class="block-3">
											{{ block.title }}
										</div>
									</div>
								</div>
							</div>
							<div class="content">
								<div v-for="p in block.content">
									<p v-if="p.p" v-html="p.p"></p>
									<p class="img">
										<img v-if="p.img" :src="p.img" :style="{width: (p.width ? `${p.width}` : 'auto')}" :alt="p.width">
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </Layout>
</template>

<script>
    import Layout from '@/components/common/Layout.vue'
    import Icon from '@/components/icon/Icon.vue'
		import DetailMixin from "@/mixins/DetailMixin";
		import BreadcrumbNew from '@/components/elements/breadcrumb/BreadcrumbNew.vue'
		import * as url from "url";

    export default {
      name: 'ServiceDetail',
			computed: {
				url() {
					return url
				}
			},
        components: {Icon, Layout, BreadcrumbNew},
        mixins: [DetailMixin],
        data() {
            return {
                id: this.$route.params.id,
                post: {}
            }
        },
        methods: {
            getPost(postId) {
                this.post = this.posts.find(item => item.id === postId);
								if(this.post && this.post.titles) {
									document.title = this.post.titles[this.post.titles.length - 1]
								}
            }
        },
        mounted() {
            this.getPost(this.id);
        }
    }
</script>
<style lang="scss" scoped>
.slider-area {

}
.detail-content {
	margin-bottom: 30px;
	font-size: 16px;
	.title{
		text-align: center;
		font-weight: bold;
		color: black;
		margin-bottom: 30px;
		display: flex;
		.block-1 {
			margin: 0 auto;
			background-color: rgb(6, 135, 244);
			height: 44px;
			min-width: 140px;
			.block-2 {
				transform: translate3d(5px, 5px, 0px);
				.block-3 {
					border-style: solid;
					border-width: 8px 1px 1px;
					border-color: rgb(0, 33, 212);
					background-color: rgb(255, 255, 255);
					padding: 4px 12px;
				}
			}
		}
	}
	.content {
		.img {
			text-align: center;
		}
	}
}
p {
	margin-bottom: 20px;
}
</style>
