<template>
    <Layout :footer-style="2" :header-transparency="true">
        <!-- Start Slider Area -->
        <div class="slider-area bg-transparent slider-style-1">
					<div class="slogan inner text-center">
						<div class="slogan1">安炘嘉得智能安全运营服务</div>
						<div class="slogan2">向用户交付卓越安全能力的一种云端赋能安全服务</div>
					</div>
        </div>

			<div class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/home1.png" alt="等级保护">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										等级保护
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										北京安炘对等级保护有深刻的理解和大量成功经验，全程为用户提供定级、备案、建设整改、测评、运维等咨询服务和解决方案。
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="rwt-about-area rn-section-gap bg-light">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										安全咨询与服务
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										安炘嘉得结合多年的安全咨询服务经验，提出了基础设施—安全体系—态势感知的安全金字塔建设理念，帮助客户逐步实现对安全风险的闭环管理和智能管控。
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/home2.png" alt="安全咨询与服务">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="rwt-about-area rn-section-gap">
				<div class="container">
					<div class="row row--30 align-items-center">
						<div class="col-lg-5 col-sm-12">
							<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
								<img class="w-100" src="../assets/images/_new/home3.png" alt="安全产品">
							</div>
						</div>
						<div class="col-lg-7 col-sm-12 mt_md--40 mt_sm--40 pl--30 pr--30">
							<div class="content">
								<div class="section-title">
									<div class="blue-title" data-aos="fade-up" data-aos-delay="280">
										安全产品
									</div>
									<p class="desc" data-aos="fade-up" data-aos-delay="310">
										安炘嘉得统一安全平台通过内置专用硬件阵列集成了下一代防火墙模块、入侵检测与防护模块、DDoS攻击防护模块、Web应用防护模块、应用管控与审计模块、运维管控与审计模块、日志审计模块、数据库审计模块、病毒防护模块、漏洞扫描模块，在对用户IT风险实现全方位管控的同时，也简化了安全架构的复杂度，从而大大降低了安全运维工作的难度。
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    import Icon from '../components/icon/Icon.vue'
    import Separator from '../components/elements/separator/Separator.vue'

    export default {
        name: 'Home',
        components: {Icon, Layout, Separator},
        data() {
            return { }
        }
    }
</script>
<style lang="scss" scoped>
.slider-area {
	background-image: url("../assets/images/_new/banner.jpg");
}
.blue-title {
	font-size: 34px;
	color: #102e98;
	line-height: 34px;
	font-weight: 400;
}
.desc{
	font-size: 20px;
	line-height: 36px;
	color: #666;
	margin: 35px 0 25px;
}
</style>
