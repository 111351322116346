<template>
    <div v-if="links.length>0 && titles.length>0" class="breadcrumb-area ptb--10">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <div class="breadcrumb-inner breadcrumb-inner-left">
                        <ul class="page-list" data-aos="fade-up" data-aos-delay="50">
                            <li class="rn-breadcrumb-item" style="padding-left: 0;"><router-link to="/">首页</router-link></li>
                            <template v-for="(item, index) in titles">
															<li v-if="index < titles.length - 1" class="rn-breadcrumb-item"><router-link :to="links[index]">{{ item }}</router-link></li>
															<li v-else class="rn-breadcrumb-item active">{{ item }}</li>
														</template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',
        props: {
            titles: {
							type: Array,
							default: () => []
						},
            links: {
                type: Array,
								default: () => []
            }
        }
    }
</script>
